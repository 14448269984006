// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: false,
    apiGatewayUrl: 'https://api.watergate.dev',
    conciergeURL: 'https://api.watergate.dev/concierge',
    albertURL: 'https://api.watergate.dev/albert',
    pusherKey: '71c900acecab6a63cee7',
    stateChangePusherEvent: 'thing-state-change',
    telemetryPusherEvent: 'smart-valve-flow-event',
    googleMapsKey: 'AIzaSyBSjz4yuSNoMUgDj-pkzOjIMFSiaJBR88c',
    refreshInterval: 60000,
    toastSettings: {
        timeOut: 4000,
        progressBar: true
    }
};
