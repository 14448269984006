<div class="modal-content">
	<div class="modal-header">
		<h4 class="modal-title"> Edit settings </h4>
		<button type="button"
				class="close"
				aria-label="Close"
				mat-icon-button
				(click)="onCancel()">
			<mat-icon>close</mat-icon>
		</button>
	</div>
	<div class="modal-body"
		 *ngIf="settings">
		<div class="row mb-3">
			<div class="col-lg-6">

				<div class="form-group">
					<label>Water cost per litre</label>
					<input type="text"
						   cFormControl
						   class="form-control"
						   [(ngModel)]="settings.water_cost_per_litre">
				</div>

			</div>
			<div class="col-lg-6">

				<div class="form-group">
					<label>Currency</label>
					<select class="form-control"
							cFormControl
							[(ngModel)]="settings.currency">
						<option *ngFor="let currency of currencies"
								[value]="currency.value">{{currency.label}}</option>
					</select>
				</div>
			</div>
            <div class="col-lg-12">

				<div class="form-group">
					<label>Water daily target usage</label>
					<input type="number"
						   class="form-control"
						   cFormControl
						   [(ngModel)]="settings.target_daily_usage_litres">
				</div>
			</div>
		</div>
        <p>Children count is deprecated and we should use only adults count.</p>
        <div class="row mb-3 align-items-center">
			<div class="col-lg-4">

				<div class="form-group">
					<label>Adults count</label>
					<input type="number"
						   cFormControl
						   class="form-control"
                           [(ngModel)]="occupantsData.adults_number">
				</div>

			</div>
			<div class="col-lg-4">

				<div class="form-group">
					<label>Children count</label>
					<input type="number"
                           disabled
						   cFormControl
						   class="form-control"
                           [(ngModel)]="occupantsData.children_number">
				</div>

			</div>
			<div class="col-lg-4">

				<div class="form-group">
					<label>
                        Has garden?
						<input type="checkbox"
							   cFormControl
                               [(ngModel)]="occupantsData.has_garden">
					</label>
				</div>

			</div>
            <div class="col-lg-6">

				<div class="form-group">
					<label>Pressure Tests schedule</label>
					<input type="time"
						   class="form-control"
						   cFormControl
						   [(ngModel)]="settings.pressure_tests_schedule">
				</div>

			</div>
            <div class="col-lg-6">

				<div class="form-group">
					<label>
						Pressure Tests Enabled
						<input type="checkbox"
							   cFormControl
							   [(ngModel)]="settings.pressure_tests_enabled">
					</label>
				</div>

			</div>
		</div>
		<div class="row mb-3">

			<div class="col-lg-12">

				<div class="form-group">
					<label>
						Auto shut off
						<input type="checkbox"
							   cFormControl
							   [(ngModel)]="settings.auto_shut_off">
					</label>
                </div>

            </div>

            <div class="col-lg-12">

                <div class="form-group">
                    <label>
                        Local API
						<input type="checkbox"
							   cFormControl
							   [(ngModel)]="settings.local_api_enabled">
                    </label>
                </div>

            </div>

			<div class="col-lg-12">

				<div class="form-group">
					<label>
						Appliance Usage Logging enabled
						<input type="checkbox"
							   cFormControl
							   [(ngModel)]="settings.appliance_logging">
					</label>
				</div>

			</div>

			<div class="col-lg-12">

				<div class="form-group">
					<label>
						Do not disturb mode enabled
						<input type="checkbox"
							   cFormControl
							   [(ngModel)]="settings.dont_disturb_mode">
					</label>
				</div>

			</div>
			<div class="col-lg-12">
				<!--				<ng-moment-timezone-picker (onselect)="changeTimezone($event)"-->
				<!--										   [setZone]="settings.timezone">-->
				<!--				</ng-moment-timezone-picker>-->

			</div>
		</div>
	</div>
	<div class="modal-footer">
		<button type="button"
				class="btn btn-secondary"
				(click)="onCancel()">Close
		</button>
		<button type="button"
				class="btn btn-primary"
				(click)="onConfirm()">Save changes
		</button>
	</div>
</div>
