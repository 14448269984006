import {ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {EChartsOption} from 'echarts';
import {lineChartDefaultOptions} from '../../../../../common/chart-defaults';
import * as moment from 'moment';
import * as _ from 'lodash';
import {HourlyStatistics} from '../../../../providers/resources/dto/hourly-statistics';

@Component({
  selector: 'app-temperature-chart',
  templateUrl: './temperature-chart.component.html',
  styleUrls: ['./temperature-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class TemperatureChartComponent implements OnInit, OnChanges {

    @Input() deviceStatistics: HourlyStatistics[];
    @Input() dateFormat: string;
    @Input() timezone: string;

    public chartOptions: EChartsOption;

    constructor() { }


    ngOnInit(): void {
        this.updateChart();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.updateChart();
    }

    private updateChart(): void {
        this.chartOptions = _.cloneDeep(lineChartDefaultOptions);

        _.merge(this.chartOptions,
            {
                title: {
                  text: 'Temperature [℃]'
                },
                useUTC: true,
                tooltip: {
                  trigger: 'axis'
                },
                legend: {
                  data: ['Max temperature', 'Min temperatuere', 'Average temperature']
                },
                xAxis: {
                  type: 'category',
                  boundaryGap: false,
                    data: this.deviceStatistics.map(a => moment.unix(a.start_date).tz(this.timezone, false).format(this.dateFormat)),
                },
                yAxis: {
                  type: 'value',
                  name: '℃',
                  min: _.round(Math.min(...this.deviceStatistics.map(a => a.temp_min)) - 5, 0),
                },
                series: [
                    {
                        name: 'Max temperature',
                        type: 'line',
                        color: 'red',
                        data: this.deviceStatistics.map(a => a.temp_max),
                      },
                      {
                        name: 'Min temperatuere',
                        type: 'line',
                        color: 'blue',
                        data: this.deviceStatistics.map(a => a.temp_min),
                      },
                      {
                        name: 'Average temperature',
                        type: 'line',
                        color: 'orange',
                        data:this.deviceStatistics.map(a => a.temp_average),
                      },
                    ]
                }
        );
    }

}
